import { Fragment } from 'react'
import Layout from './layout'
import 'react-multi-carousel/lib/styles.css'

const Page = (props: any) => {
  return (
    <Fragment>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="anonymous"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Reem+Kufi&display=swap"
        rel="stylesheet"
      />

      <Layout {...props}>{props.children}</Layout>
    </Fragment>
  )
}
export default Page
